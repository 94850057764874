import { PageLayout } from "@components";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import { Demo } from ".././biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import classNames from "classnames";

export default function Research() {
	const demoProps = {
		title: "Streamline antibodies R&D with centralized lab management software",
		subtitle:
			"Ensure precision, compliance, and security to accelerate the antibodies research process.",
		srcImg: "feeding-conveyor-packaging-materials.png",
		altImg: "feeding conveyor packaging material",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(styles.getADemoOverride, styles.clinicalGetADemoOverride),
        titleStylesOverride: styles.clinicalDemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.antibodiesDemoLeftSectionOverride
	};

	const cards: FeatureCardProps[] = [
		{
			title: "Optimize antibodies research with advanced inventory management",
			list: [
				"Manage and organize antibody samples effectively, enhancing the pace of discovery and development.",
				"Maximize resource efficiency in antibody research using Genemod's inventory management tools.",
				"Employ Genemod's electronic lab notebook for systematic recording and analysis..",
			],
			image: (
				<StaticImage
					src={
						"../../../pages/products/lab-protocol/_assets/protocols_image_2.png"
					}
					alt="Easily Manage and Submit Orders"
				/>
			),
            className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
		{
			title: "Accelerate antibody therapies",
			list: [
				"Genemod's LIMS consolidates laboratory data management, offering streamlined control over research data, sample tracking, test result documentation, quality control standards, and data analysis procedures.",
				"Genemod’s virtual freezers offer a solution for effective material management and record-keeping, along with data sharing capabilities that help reduce time spent on supplementary tasks.",
				"Utilize Genemod for efficient organization of lab consumables and manage your lab resources with a software system that captures every detail.",
			],
			image: (
				<StaticImage
					src={"../../../assets/products/single-source-of-truth.png"}
					alt="Collaborate for In-depth Order Tracking"
				/>
			),
			orientation: "right",
            className: styles.featureQuickAccess,
            sublistColor:'text-helper',
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
	];
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
			<div className={styles.ourPartners}>
				<PartnersV2 containerOverride={styles.containerOverride} />
			</div>
			<Margins className={styles.institutionsMargins}>
			<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
